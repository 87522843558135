import { About } from "components/About";
import { AboutHeader } from "components/AboutHeader";
import { CardEducation } from "components/CardEducation";
import CardsIcon from "components/CardsIcon";
import Quotes from "components/Quotes";
import { AboutSection } from "components/styles/About.styled";
import { CardsContainer, CardsSection, Title } from "components/styles/CardIcon.styled";

const Home = () => {
    const hard_skills = [        
        { name: "Flutter", icon: "https://storage.fernandes.dev.br/skill-flutter.png" },
        { name: "Laravel", icon: "https://storage.fernandes.dev.br/skill-laravel.png" },
        { name: "React", icon: "https://storage.fernandes.dev.br/skill-react.png" },
        { name: "Spring Boot", icon: "https://storage.fernandes.dev.br/skill-spring-boot.png" },
        { name: "PostgreSQL", icon: "https://storage.fernandes.dev.br/skill-postgresql.png" }
    ];

    const hobbies = [
        { name: "Domótica", icon: "https://cdn-icons-png.flaticon.com/512/7979/7979074.png" },
        { name: "Ouvir música", icon: "https://cdn-icons-png.flaticon.com/512/2829/2829075.png" },
        { name: "Jogar", icon: "https://cdn-icons-png.flaticon.com/512/2972/2972048.png" },
        { name: "Tirar fotos", icon: "https://cdn-icons-png.flaticon.com/512/2668/2668896.png"},
        { name: "Tocar violão", icon: "https://cdn-icons-png.flaticon.com/512/3659/3659784.png" }
    ];

    const courses = [
        { name: "Sistemas para internet", icon: "https://storage.fernandes.dev.br/logo-unicesumar.png", institution: "Unicesumar", year: "2019" },
        { name: "Desenvolvedor Fullstack", icon: "https://storage.fernandes.dev.br/logo-inoversasul.png", institution: "InoversaSul", year: "2022" },
        { name: "Trilha Front End", icon: "https://storage.fernandes.dev.br/logo-one.png", institution: "Alura", year: "2023" },
        { name: "Inovação e Tecnologia", icon: "https://storage.fernandes.dev.br/logo-ifsc.png", institution: "IFSC", year: "2024" },
    ];

    return (
        <>
            <AboutHeader />
            <About />
            <CardsContainer>
                <Title>
                    Skills
                </Title>
                <CardsSection>
                    {hard_skills.map((skill, index) => (
                        <CardsIcon key={index} skill={skill} />
                    ))}
                </CardsSection>
            </CardsContainer>

            <CardsContainer>
                <Title>
                    Hobbies
                </Title>
                <CardsSection>
                    {hobbies.map((hobbie, index) => (
                        <CardsIcon key={index} skill={hobbie} />
                    ))}
                </CardsSection>
            </CardsContainer>

            <AboutSection>
                <Title>Formação acadêmica</Title>
                <CardsSection>
                    {courses.map((course, index) => (
                        <CardEducation key={index} course={course} />
                    ))}
                </CardsSection>
            </AboutSection>

            <Quotes />
        </>
    );
};
export default Home;