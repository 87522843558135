import { AboutHeaderBody, AboutHeaderContainer, AboutHeaderH1, AboutHeaderImg, AboutHeaderSpan } from "./styles/AboutHeader.styled";

export const AboutHeader = () => {
    return (
        <AboutHeaderContainer>
            <AboutHeaderBody>
                <AboutHeaderH1>
                    Henrique Fernandes
                </AboutHeaderH1>
                <AboutHeaderSpan>
                    Formado em Sistemas para internet, desenvolvo aplicações web e mobile.
                </AboutHeaderSpan>
            </AboutHeaderBody>
            <AboutHeaderImg src="https://avatars.githubusercontent.com/u/12588636?v=4" alt="Fernandes Dev" />
        </AboutHeaderContainer>
    );
};