import styled from "styled-components";

export const CardsContainer = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;
`;

export const CardsSection = styled.section`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.quoteTitle};
  text-align: center;
  font: 2rem "Raleway", sans-serif;
`;

export const CardIcon = styled.img`
  width: 6rem;
  padding: 10px;
`;

export const CardTitle = styled.div`
  color: ${({ theme }) => theme.colors.quoteTitle};
  text-align: center;
  padding: 10px;
  font-family: "Raleway", sans-serif;
  font-size: 1.25rem;
`;

export const CardBody = styled.div`
  background-color: ${({ theme }) => theme.colors.quoteBgc};
  margin: 0.5rem 1rem 0 1rem;
  display: grid;
  align-content: end;
  justify-content: center;
  width: 10rem;
`;