import styled from "styled-components";

export const AboutSection = styled.section`
    background-color: ${({ theme }) => theme.colors.quoteBgc};
    padding: 50px;
`;

export const AboutContainer = styled.div`
    width: 34rem;
    margin: 0 auto;
`;

export const AboutBody = styled.p`
    color: ${({ theme }) => theme.colors.quoteTitle};
    margin-top: 15px;
    font-family: "Raleway",sans-serif;
    text-align: justify;
    font-weight: 300;
`;

export const AboutSpan = styled.span`
    display: block;
    margin-top: 10px;
`;