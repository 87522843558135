export const light = {
    name: "light-theme",
    colors: {
      header: "hsl(0, 0%, 93%)",
      background: "hsl(0, 0%, 95%)",
      footer: "hsl(0, 1%, 38%)",
      text: "hsl(0, 1%, 16%)",
      quoteBgc: "hsl(60, 40%, 100%)",
      quoteTitle: "hsl(0, 1%, 38%)",
      quoteBody: "hsl(0, 1%, 38%);",
      quoteBorder: "hsl(0, 0%, 87%)",
      border: "hsl(0, 0%, 87%)",
    },
  };
  
  export const dark = {
    name: "dark-theme",
    colors: {
      header: "hsl(0, 0%, 20%)",
      background: "hsl(0, 1%, 16%)",
      footer: "hsl(0, 0%, 93%)",
      text: "hsl(0, 0%, 100%)",
      quoteBgc: "hsl(0, 0%, 35%)",
      quoteTitle: "hsl(0, 0%, 100%)",
      quoteBody: "hsl(0, 0%, 100%)",
      quoteBorder: "hsl(0, 0%, 59%)",
      border: "hsl(0, 0%, 78%)",
    },
  };
  
  export const blue = {
    name: "blue-theme",
    colors: {
      header: "hsl(195, 53%, 79%)",
      background: "hsl(194, 100%, 97%)",
      footer: "hsl(195, 52%, 28%)",
      text: "hsl(0, 1%, 16%)",
      quoteBgc: "hsl(0, 0%, 100%)",
      quoteTitle: "hsl(195, 52%, 28%)",
      quoteBody: "hsl(0, 0%, 38%)",
      quoteBorder: "hsl(0, 0%, 87%)",
      border: "hsl(0, 0%, 87%)",
    },
  };
  
  export const green = {
    name: "green-theme",
    colors: {
      header: "hsl(150, 80%, 15%)",
      background: "hsl(150, 80%, 20%)",
      footer: "hsl(150, 80%, 80%)",
      text: "hsl(150, 80%, 80%);",
      quoteBgc: "hsl(150, 60%, 60%)",
      quoteTitle: "hsl(130, 90%, 10%)",
      quoteBody: "hsl(130, 70%, 10%)",
      quoteBorder: "hsl(130, 80%, 20%)",
      border: "hsl(170, 100%, 60%)",
    },
  };
  
  export const brown = {
    name: "brown-theme",
    colors: {
      header: "hsl(39, 70%, 50%)",
      background: "hsl(37, 83%, 54%)",
      footer: "hsl(39, 50%, 20%)",
      text: "hsl(100, 0%, 20%)",
      quoteBgc: "hsl(50, 100%, 70%)",
      quoteTitle: "hsl(37, 23%, 24%)",
      quoteBody: "hsl(30, 23%, 24%)",
      quoteBorder: "hsl(50, 50%, 50%)",
      border: "rgb(224, 189, 33)",
    },
  };
  
  export const pink = {
    name: "pink-theme",
    colors: {
      header: "hsl(350, 100%, 88%)",
      background: "hsl(300, 80%, 88%)",
      footer: "hsl(300, 10%, 28%)",
      text: "hsl(300, 100%, 28%)",
      quoteBgc: "hsl(350, 50%, 78%)",
      quoteTitle: "hsl(300, 50%, 28%)",
      quoteBody: "hsl(320, 20%, 28%)",
      quoteBorder: "hsl(300, 50%, 28%)",
      border: "hsl(300, 50%, 58%)",
    },
  };