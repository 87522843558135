import GlobalStyles from "components/styles/GlobalStyles";
import { ThemeButton, ThemeContainer } from "components/styles/ThemeSwitching.styled";
import { Footer } from "components/styles/Footer.styled";
import { light, dark, blue, green, brown, pink } from "components/styles/Theme.styled";
import React, { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { Outlet } from "react-router-dom";

const PageBase = () => {

    const [selectedTheme, setSelectedTheme] = useState(light);

    const HandleThemeChange = (theme) => {
        setSelectedTheme(theme);
        localStorage.setItem("current-theme", JSON.stringify(theme));
    };

    useEffect(() => {
        const currentTheme = JSON.parse(localStorage.getItem("current-theme"));
        if (currentTheme) {
            setSelectedTheme(currentTheme);
        }
    }, []);

    return (
        <ThemeProvider theme={selectedTheme}>
            <GlobalStyles />
            <ThemeContainer>
                <span>Themes: </span>
                <ThemeButton
                    className={`light ${selectedTheme === light ? "active" : ""}`}
                    onClick={() => HandleThemeChange(light)}></ThemeButton>
                <ThemeButton
                    className={`dark ${selectedTheme === dark ? "active" : ""}`}
                    onClick={() => HandleThemeChange(dark)}></ThemeButton>
                <ThemeButton
                    className={`blue ${selectedTheme === blue ? "active" : ""}`}
                    onClick={() => HandleThemeChange(blue)}></ThemeButton>
                <ThemeButton
                    className={`green ${selectedTheme === green ? "active" : ""}`}
                    onClick={() => HandleThemeChange(green)}></ThemeButton>
                <ThemeButton
                    className={`brown ${selectedTheme === brown ? "active" : ""}`}
                    onClick={() => HandleThemeChange(brown)}></ThemeButton>
                <ThemeButton
                    className={`pink ${selectedTheme === pink ? "active" : ""}`}
                    onClick={() => HandleThemeChange(pink)}></ThemeButton>
            </ThemeContainer>
            <Outlet />
            <Footer>
                <p>
                    Todos os direitos reservados &copy;2024
                </p>
            </Footer>
        </ThemeProvider>
    );
};

export default PageBase;