import styled from "styled-components";

export const AboutHeaderH1 = styled.h1`
    color: ${({ theme }) => theme.colors.quoteTitle};
    font: 2rem "Raleway", sans-serif;
`;

export const AboutHeaderContainer = styled.div`
    width: 40rem;
    margin: 50px auto;
    display: flex;
    align-items: center;
`;

export const AboutHeaderBody = styled.p`
    color: ${({ theme }) => theme.colors.quoteTitle};
    font-family: "Raleway",sans-serif;
    text-align: justify;
    font-weight: 300;
`;

export const AboutHeaderSpan = styled.span`
    display: block;
    width: 70%;
    margin-top: 10px;
`;

export const AboutHeaderImg = styled.img`
    width: 16rem;
    border-radius: 50%;
`;