import { AboutEducationSpan, CardEducationBody, CardEducationTitle } from "./styles/CardEducation.styled";
import { CardIcon } from "./styles/CardIcon.styled";

export const CardEducation = ({ course }) => {
    return (
        <CardEducationBody>
            <CardIcon src={course.icon} alt={'imagem ' + course.name} />
            <div>
                <CardEducationTitle>{course.name}</CardEducationTitle>
                <AboutEducationSpan>{course.year} - {course.institution}</AboutEducationSpan>
            </div>
            
        </CardEducationBody>
    );
};