import styled from "styled-components";

export const CardEducationBody = styled.div`
  background-color: ${({ theme }) => theme.colors.quoteBgc};
  color: ${({ theme }) => theme.colors.quoteTitle};
  margin: 0.5rem 1rem 0 1rem;
  display: flex;
  width: 15rem;
  justify-content: center;
  flex-wrap: wrap;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
`;

export const CardEducationTitle = styled.div`
  color: ${({ theme }) => theme.colors.quoteTitle};
  text-align: center;
  margin-top: 5px;
  font-size: 1.25rem;
`;

export const AboutEducationSpan = styled.span`
  font-size: 1rem;
`;