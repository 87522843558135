import { CardBody, CardIcon, CardTitle } from "./styles/CardIcon.styled";

const CardsIcon = ({ skill }) => {
    return (
        <CardBody>
            <CardIcon src={skill.icon} alt={'imagem ' + skill.name} />
            <CardTitle>{skill.name}</CardTitle>
        </CardBody>
    );
};

export default CardsIcon;