import React from "react";
import { CardsContainer, Card, CardTitle, CardBody } from "components/styles/Cards.styled";
import { CardIcon, CardsSection } from "./styles/CardIcon.styled";

const Quotes = () => {
  return '';
  return (
    <CardsContainer>
      <Card>
        <CardTitle>Banco de Horas</CardTitle>
        <CardsSection>
          <a href="https://bancohoras.fernandes.dev.br" target="_blank" rel="noreferrer">
            <CardIcon src="https://storage.fernandes.dev.br/skill-laravel.png" alt="Laravel" />
          </a>
        </CardsSection>
        <CardBody>
          <p>
            Uma aplicação que permite gerenciar as horas extras da empresa. <br/>
            Tecnologias: Laravel e PostgreSQL
          </p>
        </CardBody>
      </Card>

      <Card>
        <CardTitle>Codificador de texto</CardTitle>
        <CardBody>
          <p>
            Site que codifica e decodifica texto <br/>
            Tecnologias: Javascript
          </p>
        </CardBody>
      </Card>

      <Card>
        <CardTitle>CRUD clientes</CardTitle>
        <CardBody>
          <p>
            Este projeto foi desenvolvido como parte de um teste técnico para uma vaga de emprego <br/>
            Tecnologias: Spring Boot e Flutter
          </p>
        </CardBody>
      </Card>
    </CardsContainer>
  )
};

export default Quotes;