import { AboutBody, AboutContainer, AboutSection, AboutSpan } from "./styles/About.styled";
import { Title } from "./styles/CardIcon.styled";

export const About = () => {
    return (
        <AboutSection>
            <AboutContainer>
                <Title>
                    Sobre mim
                </Title>
                <AboutBody>
                    <AboutSpan>
                        Desde a infância, sempre fui fascinado por tecnologia e pelo funcionamento das coisas. Essa curiosidade me impulsiona a criar soluções inovadoras, automatizar processos e tornar o uso da tecnologia mais acessível e eficiente no dia a dia.
                    </AboutSpan>
                </AboutBody>
            </AboutContainer>
        </AboutSection>
    );
};